@font-face {
  font-family: 'Poly';
  font-style: normal;
  font-weight: normal;
  src: local('Poly'), url('Poly-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Poly Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poly Italic'), url('Poly-Italic.woff') format('woff');
}

.App {
  text-align: center;
}

@font-face {
  font-family: 'Charcoal';
  src: local('Charcoal'), url(./charcoal.ttf) format('truetype');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#root,
body,
html {
  background: #fff !important;
  height: 100% !important;
  overflow-x: hidden !important;
  position: relative !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

.home_outer {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.right_home {
  border-right: 1px solid hsla(0, 0%, 100%, .25);
  height: calc(100vh - 60px) !important;
  overflow-y: scroll;
  box-sizing: border-box;
  padding:20px;
}

.left_home {
  height: calc(100vh - 58px) !important;
  overflow-y: scroll;
  box-sizing: border-box;

   background: linear-gradient(to right, #FEAC00, #FBD800);
  padding:10px;
  justify-content:center;
}


.div1 {
  width: 20px;
  height: 50px;
  float: left;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 0.1s;
  animation-timing-function: linear;
}

.div2 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
  animation-timing-function: linear;
}

.div3 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  animation-timing-function: linear;
}

.div4 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 1.5s;
  animation-timing-function: linear;
}

.div5 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  animation-timing-function: linear;
}

.div6 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 2.5s;
  animation-timing-function: linear;
}

.div7 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 3s;
  animation-timing-function: linear;
}

.div8 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 3.5s;
  animation-timing-function: linear;
}

.div9 {
  width: 20px;
  height: 50px;
  float: left;
  background-color: none;
  animation-name: example;
  animation-duration: 4s;
  margin-right: 5px;
  animation-iteration-count: infinite;
  animation-delay: 4s;
  animation-timing-function: linear;
}

@keyframes example {
  from {
    background-color: green;
  }

  to {
    background-color: green;
  }
}

.loader{
  display: block;
  position: relative;
  height: 12px;
  width: 80%;
  border: 1px solid #fff;
  border-radius: 10px;
  overflow: hidden;
}
.loader::after {
  content: '';
  width: 40%;
  height: 100%;
  background: #2A6AFF;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}

.loader1 {
  width: 48px;
  height: 48px;
  border: 3px solid #000;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader1::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #FF3D00 transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 